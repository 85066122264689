import React, { useRef,useState } from "react";
import axios from "axios";
import { 
  IonContent, 
  IonHeader, 
  IonPage,
  IonInput, 
  IonTitle, 
  IonToolbar,
  IonItem,
  IonButton,
  IonIcon,
  IonList,
  IonToast,
  IonLoading,
  useIonAlert,
  IonGrid, IonRow, IonCol

} from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import { useForm, Controller } from "react-hook-form";
import './Common.css';
import './Route.css';
import { addOutline,pencilOutline, trashOutline } from "ionicons/icons";

const baseURL = "https://landotnet.com/mili/api/router";

const TaRouTab: React.FC = () => {

  const { control, handleSubmit, formState, reset, formState: { errors } } = useForm({
    mode: "onChange"
  });

  const [ name, setName ] = useState('');
  const [ id ,setId ] = useState(0);
  const [ listItems, setListItems ] = useState<any>([]);
  const [message, setMessage] = useState<any>(null);
  const [successToast, setSuccessToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [present] = useIonAlert();
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const inputRef = useRef<any>(null);

  /**
   *
   * @param _fieldName
   */
   const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div style={{ color: "red", fontWeight: "bold" }}>
        {error.message || "Field Is Required"}
      </div>
    ) : null;
  };

  /**
   *
   * @param data
   */
   const onSubmit = (data: any) => {
    if (!name.trim()) {
      //alert("Code or Name is invalid");
      setMessage("Code or Name is invalid");
        setErrorToast(true);
      return;
    }

    setMessage(null);

    if(id!=0){
      const putData = {
        Name: name,
      };
      setShowLoading(true);

      axios.put(baseURL+'/'+id, putData)
      .then(function (response: any) {
        //setData(res.data);
        setShowLoading(true);
        clearInputs();
        setMessage(response.data);
        setSuccessToast(true);
        setListItems([]);
        getRequest().then(data => setListItems(data));
      }).catch(error => {
        setMessage(error.message);
        setShowLoading(false);
        setErrorToast(true);
        
      });
    }else{
      //alert(JSON.stringify(data, null, 2));
      sendRequest();
    }
  };

  React.useEffect(() => {
    setListItems([]);
    getRequest().then(data => setListItems(data));
  }, []);


  const getRequest = () => {
    setShowLoading(true);
    return axios({
      url: baseURL,
      method: 'get'
    }).then(response => {
  
      console.log(response);
      setShowLoading(false);
      return response.data;
    })
  };

  const sendRequest = () => {
    //var formData = new FormData();
    setShowLoading(true);
    return axios.post(
      baseURL,
      {'Name':name}
      )
      .then(function (response: any) {
        setName('');
        setMessage(response.data);
        setSuccessToast(true);
        setListItems([]);
        getRequest().then(data => setListItems(data));
      })
      .catch(function (error) {
        console.log(error);
        //alert(error.message);
        setMessage(error.message);
        setShowLoading(false);
        setErrorToast(true);
      });
    };

    const updateItem = (item: any) => {
      contentRef.current && contentRef.current.scrollToTop(500);
      setName(item['Name']);
      setId(item['Id']);
      inputRef.current.setFocus();
    };

    const deleteRow = (id: any,e: any) => {
    
      setShowLoading(true);
      axios.delete(baseURL+'/'+id)
  
        .then(response => {
          setMessage(response.data);
          setShowLoading(false);
          setSuccessToast(true);
          setListItems([]);
          getRequest().then(data => setListItems(data));
          //listItems.filter((item: { id: any; }) => item.id !== id);  
          //setListItems(listItems.filter((item: { id: any; }) => item.id !== id));
          
        })
        .catch(function (error) {
          console.log(error);
          //alert(error.message);
          setMessage(error.message);
          setShowLoading(false);
          setErrorToast(true);
        });
    }

    const clearInputs = () => {
      setName('');
      setId(0);
    };
  


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar class='new-background-color'>
          <IonTitle>Route Details</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollEvents={true} ref={contentRef} fullscreen>
      <IonToast
       color="success"
        isOpen={successToast}
        onDidDismiss={() => setSuccessToast(false)}
        message={message}
        duration={200}
      />
      <IonToast
      color="danger"
        isOpen={errorToast}
        onDidDismiss={() => setErrorToast(false)}
        message={message}
        duration={200}
      />

      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={'Please wait...'}
        duration={5000}
      />


      <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 18 }}>
     
      <IonGrid fixed={true}>
      <IonRow>
        
        <IonCol size="8">
            <IonItem>
              <IonInput value={name} ref={(ref) => inputRef.current = ref} placeholder="Route" onIonChange={e => setName(e.detail.value!)} clearInput></IonInput>
            </IonItem>
        </IonCol>
        <IonCol size="3">
            <IonButton size="default" color="light" type="submit" disabled={formState.isValid === false}>
              <IonIcon slot="icon-only" icon={addOutline}  />
            </IonButton>
            
        </IonCol>
        
      </IonRow>
     

    </IonGrid>

          
          </form>
         
    <IonList color="primary">

      {
        listItems.map((item :any) => {

          return (
            <IonItem key={item['Id']} className="ion-item-border-color" lines="full">
              {item['Name']}
              <IonButton  color="dark" onClick={() => { updateItem(item)} } size="small" slot="end">
              <IonIcon slot="icon-only"  icon={pencilOutline}  />
              </IonButton>
              <IonButton  color="dark" onClick={() =>
            present({
              cssClass:'tertiary',
              header: 'Conformation',
              message: 'Are you sure you want to delete this Route?',
              buttons: [
                {text: 'Cancel',cssClass:'altbtncancel'},
                { text: 'Yes',cssClass:'altbtnyes', handler: (d) => deleteRow(item['Id'], d) },
              ],
              onDidDismiss: (e) => console.log('did dismiss'),
            })
          } 
          size="small" slot="end">
              <IonIcon slot="icon-only"  icon={trashOutline}  />
              </IonButton>
            </IonItem>
          );
        })
      }

</IonList>
     



      </IonContent>
    </IonPage>
  );
};


export default TaRouTab;
