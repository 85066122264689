import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, square, triangle } from 'ionicons/icons';
import { person, location, shuffle, documentTextOutline,logoFoursquare } from 'ionicons/icons';
import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';

import Driver from './pages/Driver';
import SRoute from './pages/Route';
import FD from './pages/FD';
import Transaction from './pages/Transaction';
import Report from './pages/Report';
import RDaily from './pages/RDaily';
import RDriver from './pages/RDriver';
import RFdWise from './pages/RFdWise';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './pages/Common.css';

setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/driver">
            <Driver />
          </Route>
          <Route exact path="/route">
            <SRoute />
          </Route>
          <Route exact path="/fd">
            <FD />
          </Route>
          <Route exact path="/transaction">
            <Transaction />
          </Route>
          <Route exact path="/report">
            <Report />
          </Route>

          <Route path="/daily-report">
            <RDaily />
          </Route>
          <Route path="/driver-report">
            <RDriver />
          </Route>
          <Route path="/fd-report">
            <RFdWise />
          </Route>

          <Route exact path="/">
            <Redirect to="/transaction" />
          </Route>
        </IonRouterOutlet>

        <IonTabBar slot="top">
        
          <IonTabButton  tab="tab1" class='tabbuttonclass' href="/driver">
            <IonIcon icon={person} />
            
          </IonTabButton>
          <IonTabButton tab="tab2" class='tabbuttonclass' href="/route">
            <IonIcon icon={location} />
          </IonTabButton>
          <IonTabButton tab="tab3" class='tabbuttonclass' href="/fd">
            <IonIcon icon={logoFoursquare} />
          </IonTabButton>
          <IonTabButton tab="tab4" class='tabbuttonclass' href="/transaction">
            <IonIcon icon={shuffle} />
          </IonTabButton>

          <IonTabButton tab="tab5" class='tabbuttonclass' href="/report">
            <IonIcon icon={documentTextOutline} />
          </IonTabButton>
         
        </IonTabBar>

      </IonTabs>
    </IonReactRouter>
  </IonApp>
);

export default App;