import React, { useState } from 'react';
import { useHistory} from 'react-router-dom';
import axios from "axios";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonDatetime,
  IonButton,
  IonPopover,
  IonText,
  IonSelect, IonSelectOption,
  IonIcon,
  IonToast,
  IonLoading,
  useIonAlert,
  IonGrid, IonRow, IonCol

} from '@ionic/react';
import { printOutline } from "ionicons/icons";
import { useForm } from "react-hook-form";
import { format, parseISO } from 'date-fns';
import './Common.css';
import './Report.css';


const driverURL = "https://landotnet.com/mili/api/driver";
const fdURL = "https://landotnet.com/mili/api/fd";


const Reportt: React.FC = () => {
  const { control, handleSubmit, formState, reset, formState: { errors } } = useForm({
    mode: "onChange"
  });

  const [listDriverItems, setListDriverItems] = useState<any>([]);
  const [listFdItems, setLisFdItems] = useState<any>([]);
  const [message, setMessage] = useState<any>(null);
  const [successToast, setSuccessToast] = useState(false);
  const [present] = useIonAlert();
  const [errorToast, setErrorToast] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [drivers, setDriversId] = useState(0);
  const [fd_id, setFdId] = useState(0);
  const today = new Date().toISOString().slice(0, 10);
  const [popoverFromDate, setPopoverFromDate] = useState(today);
  const [popoverToDate, setPopoverToDate] = useState(today);
  const current = new Date();
  let shortMonth = current.toLocaleString('en-us', { month: 'short' });
  const [popoverDeliveryMonth, setPopoverDeliveryMonth] = useState((shortMonth + ' ' + current.getFullYear()));
  const [popoverWageMonth, setPopoverWageMonth] = useState((shortMonth + ' ' + current.getFullYear()));

  const customPopoverOptions = {
    cssClass: 'customAlertCss'
  };
  React.useEffect(() => {
    setShowLoading(true);
    axios({ url: driverURL, method: 'get' })
      .then(response => {
        setListDriverItems(response.data);
      }).catch(error => {
        setMessage(error.message);
        setShowLoading(false);
        setErrorToast(true);
      });
    axios({ url: fdURL, method: 'get' })
      .then(response => {
        setLisFdItems(response.data);
      }).catch(error => {
        setMessage(error.message);
        setShowLoading(false);
        setErrorToast(true);
      });
    setShowLoading(false);

  }, []);

  const formatDate = (value: any) => {
    return format(parseISO(value), 'dd MMM yyyy');
  };
  const formatMonth = (value: any) => {
    return format(parseISO(value), 'MMM yyyy');
  };

  const onDailySubmit = (data: any, e: any) => {
    //const {fullname,age,gender,location}= data;
    //const userData = {fullname,age,gender,location,patPhoneNumber};
    //firestore.collection('pat_profile').doc(auth.currentUser.uid).set({fullname,age,gender,location,patPhoneNumber});
    //history.push('/WelcomePage');

  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar class='new-background-color'>
          <IonTitle>Report Details</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

        <IonToast
          color="success"
          isOpen={successToast}
          onDidDismiss={() => setSuccessToast(false)}
          message={message}
          duration={600}
        />
        <IonToast
          color="danger"
          isOpen={errorToast}
          onDidDismiss={() => setErrorToast(false)}
          message={message}
          duration={400}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          duration={5000}
        />

        <IonGrid >
          <IonRow >

            <IonCol size="4">

              <h6>Daily Transaction</h6>
            </IonCol>

            <IonCol size="6">
              <IonItem button={true} >
                <p>
                <IonText slot="start">From -:</IonText>
                <IonText id="from-daily-transaction" slot="start">{popoverFromDate}</IonText>
                <IonPopover trigger="from-daily-transaction"  >
                  <IonDatetime
                    presentation="date"
                    onIonChange={ev => setPopoverFromDate(formatDate(ev.detail.value!))}
                  />
                </IonPopover>
                </p>
                <p >To -:
                  <IonText id="to-daily-transaction" slot="start">{popoverToDate}</IonText>
                  <IonPopover trigger="to-daily-transaction"  >
                    <IonDatetime
                      presentation="date"
                      onIonChange={ev => setPopoverToDate(formatDate(ev.detail.value!))}
                    />
                  </IonPopover>
                  </p>
                </IonItem>

            </IonCol>
            <IonCol size="2">


              <IonButton size="default" color="light" onClick={(e) => {
                e.preventDefault();
                window.location.href = '/daily-report?from_date=' + popoverFromDate + '&to_date=' + popoverToDate
              }} type="submit" >
                <IonIcon slot="icon-only" icon={printOutline} />
              </IonButton>

            </IonCol>
          </IonRow>

          <IonRow >
            <IonCol size="4">

              <h6>Transaction Driver Wise</h6>
            </IonCol>

            <IonCol size="6">
              <IonItem>
                <IonSelect interface="popover" multiple={true} interfaceOptions={customPopoverOptions} value={drivers} name='driver_id' placeholder="Driver" onIonChange={e => setDriversId(e.detail.value)}>
                  {
                    listDriverItems.map((item: any) => {
                      return (
                        <IonSelectOption key={item['Id']} value={item['Id']}>
                          {item['Name']}
                        </IonSelectOption>
                      );
                    })
                  }
                </IonSelect>
              </IonItem>

            </IonCol>

            <IonCol size="2">
              <IonButton size="default" color="light" type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = '/driver-report?driver=' + drivers
                }} >
                <IonIcon slot="icon-only" icon={printOutline} />
              </IonButton>

            </IonCol>

          </IonRow>

          <IonRow >
            <IonCol size="4">

              <h6>Transaction FD Wise</h6>
            </IonCol>

            <IonCol size="6">
              <IonItem>
                <IonSelect interface="popover" multiple={true} interfaceOptions={customPopoverOptions} value={fd_id} name='fd_id' placeholder="FD" onIonChange={e => setFdId(e.detail.value)}>
                  {
                    listFdItems.map((fditem: any) => {
                      return (
                        <IonSelectOption key={fditem['Id']} value={fditem['Id']}>
                          {fditem['Name']}
                        </IonSelectOption>
                      );
                    })
                  }
                </IonSelect>
              </IonItem>

            </IonCol>

            <IonCol size="2">
              <IonButton size="default" color="light" onClick={(e) => {
                e.preventDefault();
                window.location.href = '/fd-report?fd=' + fd_id
              }} type="submit" >
                <IonIcon slot="icon-only" icon={printOutline} />
              </IonButton>

            </IonCol>

          </IonRow>

          <IonRow >
            <IonCol size="4">

              <h6>Monthly Deliveries</h6>
            </IonCol>

            <IonCol size="6">
              <IonItem button={true} >
                <IonText id="open-month-deliveries" slot="start">{popoverDeliveryMonth}</IonText>
                <IonPopover trigger="open-month-deliveries" dismissOnSelect={true} >
                  <IonDatetime
                    presentation="month-year"
                    onIonChange={ev => setPopoverDeliveryMonth(formatMonth(ev.detail.value!))}
                  />
                </IonPopover>
              </IonItem>

            </IonCol>

            <IonCol size="2">
              <IonButton size="default" color="light" onClick={(e) => {
                e.preventDefault();
                //window.location.href = '/daily?from_date=' + popoverFromDate + '&to_date=' + popoverToDate
              }} type="submit" >
                <IonIcon slot="icon-only" icon={printOutline} />
              </IonButton>

            </IonCol>

          </IonRow>

          <IonRow >
            <IonCol size="4">

              <h6>Monthly Wages</h6>
            </IonCol>

            <IonCol size="6">
              <IonItem button={true} >
                <IonText id="open-month-wages" slot="start">{popoverWageMonth}</IonText>
                <IonPopover trigger="open-month-wages" dismissOnSelect={true} >
                  <IonDatetime
                    presentation="month-year"
                    //onIonChange={handleChange}
                    onIonChange={ev => setPopoverWageMonth(formatMonth(ev.detail.value!))}
                  />
                </IonPopover>
              </IonItem>

            </IonCol>

            <IonCol size="2">
              <IonButton size="default" color="light" onClick={(e) => {
                e.preventDefault();
                //window.location.href = '/daily?from_date=' + popoverFromDate + '&to_date=' + popoverToDate
              }} type="submit" >
                <IonIcon slot="icon-only" icon={printOutline} />
              </IonButton>

            </IonCol>

          </IonRow>


        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Reportt;