import React, { useState } from "react";
import axios from "axios";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonToast,
  IonLoading,
  useIonAlert,
} from '@ionic/react';


import { addOutline, pencilOutline, trashOutline } from "ionicons/icons";
import './Common.css';


const RDriver: React.FC = () => {


  const query = new URLSearchParams(window.location.search);
  const fd = query.get('fd');


  const [listTranItems, setLisTrantItems] = useState<any>([]);
  const [message, setMessage] = useState<any>(null);
  const [successToast, setSuccessToast] = useState(false);
  const [present] = useIonAlert();
  const [errorToast, setErrorToast] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const baseURL = "https://landotnet.com/mili/api/fdreport";

  React.useEffect(() => {
    setLisTrantItems([]);
    setShowLoading(true);

    axios({
      url: baseURL,
      params: { fd: fd},
      method: 'get'
    })
      .then(response => {
        setLisTrantItems(response.data);

      }).catch(error => {
        setMessage(error.message);
        setShowLoading(false);
        setErrorToast(true);
      });

    setShowLoading(false);

  }, []);



  return (
    <IonPage>
      <IonHeader>
        <IonToolbar class='new-background-color'>
          <IonTitle>FD Wise Report</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollEvents={true} fullscreen>

        <IonToast
          color="success"
          isOpen={successToast}
          onDidDismiss={() => setSuccessToast(false)}
          message={message}
          duration={600}
        />
        <IonToast
          color="danger"
          isOpen={errorToast}
          onDidDismiss={() => setErrorToast(false)}
          message={message}
          duration={400}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          duration={5000}
        />
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Driver</th>
              <th>FD</th>
              <th>Route</th>
              <th>Stops</th>
            </tr>
          </thead>
          <tbody>
          {
            listTranItems.map((tranitem: any,index:any) => {

            return (
            <tr key={index}>
              <td>{tranitem['fd']}</td>
              <td>{tranitem['Date']}</td>
              <td> {tranitem['Name']}</td>
              <td>{tranitem['route']}</td>
              <td> {tranitem['Stops']}</td>
            </tr>
            );
          })
        }
          </tbody>
        </table>


      </IonContent>
    </IonPage>
  );
};

export default RDriver;