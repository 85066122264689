import React, {useState } from "react";
import axios from "axios";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonToast,
  IonLoading,
  useIonAlert

} from '@ionic/react';
import './Common.css';


const RDaily: React.FC = () => {


  const query = new URLSearchParams(window.location.search);
  const from_date = query.get('from_date');
  const to_date = query.get('to_date');
  const [listTranItems, setLisTrantItems] = useState<any>([]);
  const [message, setMessage] = useState<any>(null);
  const [successToast, setSuccessToast] = useState(false);
  const [present] = useIonAlert();
  const [errorToast, setErrorToast] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const baseURL = "https://landotnet.com/mili/api/dreport";

  React.useEffect(() => {
    setLisTrantItems([]);
    setShowLoading(true);

    axios({
      url: baseURL,
      params: { from_date: from_date, to_date: to_date },
      method: 'get'
    })
      .then(response => {
        setLisTrantItems(response.data);

      }).catch(error => {
        setMessage(error.message);
        setShowLoading(false);
        setErrorToast(true);
      });

    setShowLoading(false);

  }, []);



  return (
    <IonPage>
      <IonHeader>
        <IonToolbar class='new-background-color'>
          <IonTitle>Daily Transaction Report</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollEvents={true} fullscreen>

        <IonToast
          color="success"
          isOpen={successToast}
          onDidDismiss={() => setSuccessToast(false)}
          message={message}
          duration={600}
        />
        <IonToast
          color="danger"
          isOpen={errorToast}
          onDidDismiss={() => setErrorToast(false)}
          message={message}
          duration={400}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          duration={5000}
        />
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Driver</th>
              <th>FD</th>
              <th>Route</th>
              <th>Stops</th>
            </tr>
          </thead>
          <tbody>
          {
            listTranItems.map((tranitem: any,index:any) => {

              return (
            <tr key={index}>
              <td >{tranitem['Date']}</td>
              <td> {tranitem['Name']}</td>
              <td>{tranitem['fd']}</td>
              <td>{tranitem['route']}</td>
              <td> {tranitem['Stops']}</td>
            </tr>
            );
          })
        }
          </tbody>
        </table>


      </IonContent>
    </IonPage>
  );
};

export default RDaily;