import React, { useState, useRef } from 'react';
import axios from "axios";
import { 
  IonContent, 
  IonHeader, 
  IonPage,
  IonInput, 
  IonTitle, 
  IonToolbar,
  IonItem,
  IonDatetime,
  IonButton,
  IonPopover,
  IonLabel,
  IonText,
  IonSelect, IonSelectOption,
  IonIcon,
  IonList,
  IonToast,
  IonLoading,
  useIonAlert,
  IonGrid, IonRow, IonCol

} from '@ionic/react';
import { useForm } from "react-hook-form";
import './Common.css';
import './Transaction.css';
import { format, parseISO } from 'date-fns';
import { addOutline,pencilOutline,trashSharp } from "ionicons/icons";


const Transaction: React.FC = () => {
  const { control, handleSubmit, formState, reset, formState: { errors } } = useForm({
    mode: "onChange"
  });
  const [selectedDate, setSelectedDate] = useState('2012-12-15T13:47:20.789');
  
  const today = new Date().toISOString().slice(0, 10);
  const [popoverDate, setPopoverDate] = useState(today);
  const [message, setMessage] = useState<any>(null);
  const [successToast, setSuccessToast] = useState(false);
  const [present] = useIonAlert();
  const [errorToast, setErrorToast] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [ listDriverItems, setListDriverItems ] = useState<any>([]);
  const [ listTranItems, setLisTrantItems ] = useState<any>([]);
  const [ listFdItems, setLisFdItems ] = useState<any>([]);
  const [ listRouteItems, setLisRouteItems ] = useState<any>([]);
   
  const baseURL = "https://landotnet.com/mili/api/transaction";
  const driverURL = "https://landotnet.com/mili/api/driver";
  const fdURL = "https://landotnet.com/mili/api/fd";
  const routeURL = "https://landotnet.com/mili/api/router";
  const [ driverIds, setDriverIds ] = useState(0);
  const [ rows, setRows ] = useState<any>([]);let tranRows= Array();
  

  const customPopoverOptions = {
    cssClass: 'customAlertCss'
  };

  React.useEffect(() => {
    setLisTrantItems([]);
    setShowLoading(true);

    axios({
      url: baseURL,
      params: { date: popoverDate},
      method: 'get'
    })
    .then(response => {
      setLisTrantItems(response.data);
      console.log(listTranItems);
      makeTranArray();
      
    }).catch(error => {
      setMessage(error.message);
      setShowLoading(false);
      setErrorToast(true);
    });

    axios({url: driverURL,method: 'get'})
    .then(response => {
      setListDriverItems(response.data);
    }).catch(error => {
      setMessage(error.message);
      setShowLoading(false);
      setErrorToast(true);
    });

    axios({url: fdURL,method: 'get'})
    .then(response => {
      setLisFdItems(response.data);
    }).catch(error => {
      setMessage(error.message);
      setShowLoading(false);
      setErrorToast(true);
    });

    axios({url: routeURL,method: 'get'})
    .then(response => {
      setLisRouteItems(response.data);
      
    }).catch(error => {
      setMessage(error.message);
      setShowLoading(false);
      setErrorToast(true);
    });

    setShowLoading(false);

  }, []);

  const getRequest = () => {
    axios({
      url: baseURL,
      params: { date: popoverDate},
      method: 'get'
    })
    .then(response => {
      setLisTrantItems(response.data);
      console.log(listTranItems);
      makeTranArray();
      
    }).catch(error => {
      setMessage(error.message);
      setShowLoading(false);
      setErrorToast(true);
    });
  };


  const onSubmit = (data: any) => {
    //console.log("creating a new user account with: ", data);
    if (driverIds==0) {
      //alert("Code or Name is invalid");
        setMessage("Select Driver");
        setErrorToast(true);
        return;
    }
    setMessage(null);
    sendRequest();
    
  };

  const handleChange = (event: any) => {
    setPopoverDate(formatDate(event.detail.value!));
    setLisTrantItems([]);
    setShowLoading(true);

    axios({
      url: baseURL,
      params: { date: event.detail.value},
      method: 'get'
    })
    .then(response => {
      setLisTrantItems(response.data);
    }).catch(error => {
      setMessage(error.message);
      setShowLoading(false);
      setErrorToast(true);
    });
    //setMessage(null);
    //sendRequest();
    
  };

  const sendRequest = () => {
    //var formData = new FormData();
    setShowLoading(true);
    return axios.post(
      baseURL,
      {
        'DriverId':driverIds,
        'FDId':0,
        'Stops':0,
        'Date':popoverDate,
      }
      )
      .then(function (response: any) {
        setMessage(response.data);
        setSuccessToast(true);
        setLisTrantItems([]);
        setDriverIds(0);
        axios({url: baseURL,params: { date: popoverDate},method: 'get'})
        .then(response => {
          setLisTrantItems(response.data);
        }).catch(error => {
          setMessage(error.message);
          setShowLoading(false);
          setErrorToast(true);
        });
      })
      .catch(function (error) {
        console.log(error);
        //alert(error.message);
        setMessage(error.message);
        setShowLoading(false);
        setErrorToast(true);
      });
    };



const deleteRow = (id: any,e: any) => {
    
      setShowLoading(true);
      axios.delete(baseURL+'/'+id)
        .then(response => {
          setMessage(response.data);
          setShowLoading(false);
          setSuccessToast(true);
          axios({url: baseURL,params: { date: popoverDate},method: 'get'})
          .then(response => {
            setLisTrantItems(response.data);
          }).catch(error => {
            setMessage(error.message);
            setShowLoading(false);
            setErrorToast(true);
          });
        })
        .catch(function (error) {
          console.log(error);
          //alert(error.message);
          setMessage(error.message);
          setShowLoading(false);
          setErrorToast(true);
        });
  
    
  
    }

    const handletrnSubmit = (event: { preventDefault: () => void; target: any; }) => {
      event.preventDefault();
      const data = new FormData(event.target);
      console.log();
      const putData = {
        RouteId: data.get('RouteId'),
        FDId: data.get('FDId'),
        Stops: data.get('Stop'),
      };
      setShowLoading(true);
      axios.put(baseURL+'/'+data.get('TId'), putData)
      .then(function (response: any) {
        //setData(res.data);
        setShowLoading(true);
        setMessage(response.data);
        setSuccessToast(true);
        setLisTrantItems([]);
        getRequest();
      }).catch(error => {
        setMessage(error.message);
        setShowLoading(false);
        setErrorToast(true);
        
      });
    }
    


  const formatDate = (value: any) => {
    return format(parseISO(value), 'dd MMM yyyy');
  };

  const rowOnChange = (index:number,k: string,v: any) => {
    //alert(index);
    //setValue(event.target.value)
    //tranRows[index][k]=event;
    //console.log(rows);
    tranRows = rows;
    tranRows[index][k]=v;
    //console.log(tranRows);
    console.log(tranRows[index][k]);
    setRows(tranRows);
    console.log(rows);
    //setRows()
  //this.setState({ urlTabs: tempTabs })
  }


  const makeTranArray = () => {
    //console.log(listTranItems);
    if (listTranItems && listTranItems.length) {
      
      // array exists and is not empty
      listTranItems.map((ti :any) => {
        //console.log(ti.Id);
        let rid:number = ti.Id;
        tranRows[rid]=ti;
        //tranRows[ti['Id']]['TId']=ti['Id'];
        //tranRows[ti['Id']]['RouteId']=ti['RouteId'];
        //tranRows[ti['Id']]['FDId']=ti['FDId'];
        //tranRows[ti['Id']]['Stops']=ti['Stops'];
        
      });
      setRows(tranRows);
      console.log(listTranItems);
      //tranRows.length = 0;
      
      console.log(tranRows);
      //console.log(tranRows[17].DriverId);
      
    }

  };
  

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar class='new-background-color'>
          <IonTitle class='ion-title'>Daily Transaction</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
      <IonToast
       color="success"
        isOpen={successToast}
        onDidDismiss={() => setSuccessToast(false)}
        message={message}
        duration={600}
      />
      <IonToast
      color="danger"
        isOpen={errorToast}
        onDidDismiss={() => setErrorToast(false)}
        message={message}
        duration={400}
      />

      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={'Please wait...'}
        duration={5000}
      />
        {/* Datetime in popover with cover element */}
      <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 18 }}>
      <IonGrid >
        <IonRow > 

        <IonCol size="5">
              
        <IonItem>
            <IonSelect interface="popover" multiple={true} interfaceOptions={customPopoverOptions} value={driverIds} name='driver_id' placeholder="Driver" onIonChange={e => setDriverIds(e.detail.value)}>
            {
              listDriverItems.map((item :any) => {
                return (
                        <IonSelectOption key={item['Id']} value={item['Id']}>
                          {item['Name']}
                        </IonSelectOption>
                );
              })
              }
            </IonSelect>
          </IonItem>
          
        </IonCol>
        <IonCol size="3">
            <IonButton size="default" color="light" type="submit" >
              <IonIcon slot="icon-only" icon={addOutline}  />
            </IonButton>
            
        </IonCol>
          <IonCol size="4">
              
          <IonItem button={true} >
        <IonText id="open-date-input" slot="start">{popoverDate}</IonText>
        <IonPopover trigger="open-date-input" showBackdrop={false}>
          <IonDatetime
            presentation="date"
            onIonChange={handleChange}
            
            //onIonChange={ev => setPopoverDate(formatDate(ev.detail.value!))}
          />
          
          
        </IonPopover>
      </IonItem>
          
          </IonCol>
          
        </IonRow>

      </IonGrid>
      </form>
      <IonList color="primary">

      {
        listTranItems.map((tranitem :any) => {

          return (
            <>
            <form className='transform' onSubmit={handletrnSubmit} >
            
           
            <IonItem key={tranitem['Id']} className="ion-item-border-color" lines="full">
            <IonGrid >
              <IonRow no-padding  > 
              <IonCol no-padding  size="3">
                <IonLabel class="ion-text-wrap" >
                  {tranitem['Name']}
                  <IonInput value={tranitem['Id']} style={{ display: 'none' }} defaultValue={tranitem['Id']} name="TId" onIonInput={(e: any) => rowOnChange(tranitem['Id'],'TId',e.target.defaultValue)}></IonInput>
                </IonLabel>
                </IonCol>
              
                <IonCol no-padding size="2">
                  <IonSelect interface="popover" interfaceOptions={customPopoverOptions} defaultValue={tranitem['RouteId']} value={tranitem['RouteId']} name='RouteId' placeholder="Route" onIonChange={e => rowOnChange(tranitem['Id'],'RouteId',e.detail.value)}>
                    {
                      listRouteItems.map((ritem :any) => {
                        return (
                                <IonSelectOption  key={ritem['Id']} value={ritem['Id']}>
                                  {ritem['Name']}
                                </IonSelectOption>
                        );
                      })
                      }
                  </IonSelect>
                </IonCol>
                <IonCol no-padding size="3">
                  <IonSelect interface="popover" interfaceOptions={customPopoverOptions} defaultValue={tranitem['FDId']} value={tranitem['FDId']} name='FDId' placeholder="FD" onIonChange={e => rowOnChange(tranitem['Id'],'FDId',e.detail.value)}>
                    {
                      listFdItems.map((fitem :any) => {
                        return (
                                <IonSelectOption  value={fitem['Id']}>
                                  {fitem['Name']}
                                </IonSelectOption>
                        );
                      })
                    }
                  </IonSelect>
                </IonCol>
                <IonCol  size="2">
                  <IonInput value={tranitem['Stops']} no-padding name="Stop" type='number'  placeholder="Stop" onIonChange={e => rowOnChange(tranitem['Id'],'Stops',e.detail.value)}  ></IonInput>
                </IonCol>
                <IonCol class="right-botton" size="2">
                  <IonButton no-padding color="dark"  type="submit" size="small">
                    <IonIcon no-padding slot="icon-only" icon={pencilOutline} />
                  </IonButton>
                  <IonButton  size="small" onClick={() =>
            present({
              cssClass: 'my-custom-class',
              header: 'Conformation',
              message: 'Are you sure you want to delete this Transaction?',
              buttons: [
                {text: 'Cancel',cssClass:'altbtncancel'},
                { text: 'Yes',cssClass:'altbtnyes', handler: (d) => deleteRow(tranitem['Id'], d) },
              ],
              onDidDismiss: (e) => console.log('did dismiss'),
            })
          }   >
                    <IonIcon  slot="icon-only"  icon={trashSharp} />
                  </IonButton>
                </IonCol>
              </IonRow >
              </IonGrid >  
            
            
            </IonItem>
            </form>
            </>

          );
        })
      }

</IonList>
      
        
      </IonContent>
    </IonPage>
  );
};

export default Transaction;

function method(arg0: { url: string; }, arg1: { params: { ID: number; }; }, method: any, arg3: string) {
  throw new Error('Function not implemented.');
}
